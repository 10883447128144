import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// All pages
import Home from './pages/Home';

import { useDocTitle } from './hooks/CustomHook';
import ScrollToTop from './hooks/ScrollToTop';
import ListJob from './pages/ListJob';
import Signin from './pages/Signin';
import JobDetail from './pages/JobDetail';
import Profile from './pages/Profile';
import Notification from './pages/Notification';
import AdminDashboard from './pages/AdminDashboard';
import ForgetPassword from './pages/ForgotPassword';
import TermsAndConditions from './pages/Legal/terms';
import PrivacyPolicy from './pages/Legal/privacy';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });

  }, []);

  useDocTitle("Project Mela");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/list-job" element={<ListJob />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/job-detail/:jobID" element={<JobDetail />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/nda-agreement" element={<AdminDashboard />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;


{/* <Link to="/contact" className="bg-transparent border hover:bg-blue-900 hover:border-blue-800 text-white justify-center text-center rounded-lg px-10 py-3 flex items-center group">Send a message
  <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
</Link> */}
