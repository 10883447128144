//Main Imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


//Frontend Imports
import '../CSS/Profile.css';
import point from "../images/points.png";
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import Loader from '../components/Loader/Loader';
import SkillsDropdown from '../components/SkillsDropdown/SkillsDropdown';

//Component Imports
import maleSymbol from '../images/icons/male-symbol.png';
import femaleSymbol from '../images/icons/female-symbol.png';
import transSymbol from '../images/icons/trans-symbol.png';
import GradientButton from '../components/General/GradientButton/GradientButton';
import FileUploadBox from '../components/General/FileUploadBox/FileUploadBox';

//Hooks
import { useUserProfile } from '../hooks/useUserProfile';

//Data and Function Imports
import { maleAvatars, femaleAvatars, genderOptions } from "../data/data";
import { maxLengthChecksforProfile } from '../data/data';
import { skillOptions } from '../data/data';
import { validateProfileInputs } from '../utils/profileValidator';
import { countDigits, renderError } from '../utils/otherFunctions';



//Backend Imports
import { updateDoc, doc, Timestamp } from 'firebase/firestore';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { db } from '../firebaseConfig/Firebase';
import pdfToText from 'react-pdftotext';
import { processResumeWithAI } from '../utils/aiLogic';




const Profile = () => {

    const navigate = useNavigate();

    const { userData, setUserData, selectedSkills, setSelectedSkills } = useUserProfile();
    const [isEditing, setIsEditing] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [showFullAboutMe, setShowFullAboutMe] = useState(false);
    const [avatarIndex, setAvatarIndex] = useState(0);
    const [phoneError, setPhoneError] = useState('');
    const [extractedText, setExtractedText] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);




    const geminiApiKey = process.env.REACT_APP_GEMINI_API_KEY;
    const genAI = new GoogleGenerativeAI(geminiApiKey);


    //Loader Logic

    if (!userData) {
        return <Loader />;
    }

    //Basic Frontend Logics

    const handlePdfClick = () => {
        setShowFileUpload(true);
    };

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    const toggleAboutMe = (e) => {
        e.preventDefault();
        setShowFullAboutMe(!showFullAboutMe);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePdfFile = async (file) => {
        setIsEditing(true);
        setIsProcessing(true);
        let retryCount = 0; // Initialize retry count
        const maxRetries = 2; // Maximum number of retries

        while (retryCount <= maxRetries) {
            try {
                const text = await pdfToText(file);
                setExtractedText(text);
                console.log("PDF text extracted successfully!");

                const result = await processResumeWithAI(text, geminiApiKey);

                setUserData(prevState => ({
                    ...prevState,
                    ...result.userData
                }));

                setSelectedSkills(result.skills);
                setShowFileUpload(false);
                toast.success("Resume processed successfully!");
                break; // Exit the loop if successful
            } catch (error) {
                retryCount++;
                console.error(`Failed to process resume: Attempt ${retryCount}`, error);
                if (retryCount > maxRetries) {
                    console.error("Error details:", error.message);
                    toast.error("Failed to process resume. Please try again.");
                    setShowFileUpload(false);
                }
            } finally {
                setIsProcessing(false);
            }
        }
    };



    const handleAvatarChange = (direction) => {
        const avatars = userData.Gender === 'Male' ? maleAvatars : userData.Gender === 'Female' ? femaleAvatars : [...maleAvatars, ...femaleAvatars];
        if (direction === 'left') {
            setAvatarIndex((prevIndex) => (prevIndex - 1 + avatars.length) % avatars.length);
        } else {
            setAvatarIndex((prevIndex) => (prevIndex + 1) % avatars.length);
        }
        setUserData(prevState => ({
            ...prevState,
            profilePic: avatars[avatarIndex]
        }));
    };


    const handlePhoneChange = (value, country) => {
        const digitsCount = countDigits(value.slice(country.dialCode.length));
        if (digitsCount < 10) {
            setPhoneError('Invalid Phone Number');
        } else {
            setPhoneError('');
        }
        console.log(countDigits(value.slice(country.dialCode.length)))
        setUserData(prevState => ({
            ...prevState,
            Mobile: `+${country.dialCode}-${value.slice(country.dialCode.length)}`
        }));
    };

    const renderErrorMessage = (field) => renderError(userData, field, maxLengthChecksforProfile);

    //Save the Data to firebase


    const handleSave = async () => {
        if (!validateProfileInputs(userData, selectedSkills)) {
            return;
        }
        if (phoneError !== '') {
            toast.error('Invalid phone number');
            return;
        }

        if (!userData.Gender) {
            toast.warning('Please select a gender');
            return;
        }

        // check for items in selectedSkills array min 1 max 20
        if (selectedSkills.length < 1) {
            toast.error('Please select at least 1 skill');
            return;
        } else if (selectedSkills.length > 20) {
            toast.error('You can only select up to 20 skills');
            return;
        }

        try {
            const userDocRef = doc(db, "Users", userData.Email);
            const updatedData = {
                ...userData,
                fullName: `${userData.FirstName} ${userData.LastName}`,
                Skills: selectedSkills,
                isProfileCompleted: true,
                DateOfBirth: Timestamp.fromDate(new Date(userData.DateOfBirth)),
                Gender: userData.Gender
            };
            await updateDoc(userDocRef, updatedData);
            setIsEditing(false);
            toast.success("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error("Failed to update profile. Please try again.");
        }
    };

    return (
        <div>
            <div className="main-content">
                {/* Header */}
                <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style={{ minHeight: '200px', backgroundImage: 'url(https://img.freepik.com/free-vector/minimal-diagonal-zigzag-pattern-design_1017-15351.jpg)', backgroundSize: 'cover', backgroundPosition: 'center top' }}>
                    {/* Mask */}
                    <span className="mask bg-gradient-default opacity-8">
                        <button class="home-back-button" onClick={() => navigate('/')}>
                            <div class="home-back-button-sign">
                                <i class="bi bi-arrow-left-short" style={{ color: 'white', fontSize: '2rem' }}></i>
                                <div class="home-back-button-text">Home</div>

                            </div>

                        </button>


                    </span>
                    {/* Header container */}
                    <div className="container-fluid d-flex align-items-center">

                        <div className="col">

                            <div className="col-lg-12 col-md-12 d-flex justify-content-between align-items-center">
                                <h1 className="display-2 text-white">Profile</h1>
                            </div>

                        </div>
                    </div>

                </div>
                {/* Page content */}
                <div className="container-fluid mt--7">
                    <div className="row">
                        <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                            <div className="card card-profile shadow">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 order-lg-2">
                                        <div className="card-profile-image">
                                            <a>
                                                <img src={userData.profilePic || "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"} className="rounded-circle" alt="Profile" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">

                                    </div>
                                </div>
                                <div className="card-body pt-0 pt-md-4">
                                    <div className="row">
                                        <div className="col">
                                            <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                                {/* Will share more info about user when we have more cloud storage space and backend cost */}
                                                {/* <div>
                                                    <span className="heading">22</span>
                                                    <span className="description">Friends</span>
                                                </div>
                                                <div>
                                                    <span className="heading">10</span>
                                                    <span className="description">Photos</span>
                                                </div>
                                                <div>
                                                    <span className="heading">89</span>
                                                    <span className="description">Comments</span>
                                                </div> */}
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={point} alt="Points Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                    <span style={{ fontSize: '14px', fontWeight: '600' }}>{userData.XP}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">

                                        {isEditing && (
                                            <div className="avatar-selection">
                                                <button onClick={() => handleAvatarChange('left')} className="avatar-control-btn">&lt;</button>
                                                <span className="avatar-selection-text">Choose your avatar</span>
                                                <button onClick={() => handleAvatarChange('right')} className="avatar-control-btn">&gt;</button>
                                            </div>
                                        )}

                                        <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {userData.FirstName} {userData.LastName}
                                            {userData.Gender === 'Male' && <img src={maleSymbol} alt="Male" style={{ width: '20px', height: '20px', marginLeft: '15px' }} />}
                                            {userData.Gender === 'Female' && <img src={femaleSymbol} alt="Female" style={{ width: '20px', height: '20px', marginLeft: '15px' }} />}
                                            {userData.Gender === 'Non Binary' && <img src={transSymbol} alt="Non Binary" style={{ width: '20px', height: '20px', marginLeft: '15px' }} />}
                                        </h3>

                                        {/* <div className="h5 font-weight-300">
                                            <i className="ni location_pin mr-2"></i>Bucharest, Romania
                                        </div> */}
                                        <div className="h5 mt-4">
                                            <i className="ni business_briefcase-24 mr-2"></i>{userData.Designation}
                                        </div>

                                        {/* <div>
                                            <i className="ni education_hat mr-2"></i>University of Computer Science
                                        </div> */}

                                        <p>
                                            {showFullAboutMe
                                                ? userData.AboutMe
                                                : userData.AboutMe.slice(0, 250) + (userData.AboutMe.length > 250 ? '...' : '')}
                                        </p>
                                        {userData.AboutMe.length > 250 && (
                                            <a href="#" onClick={(e) => toggleAboutMe(e)}>
                                                {showFullAboutMe ? 'Show Less' : 'Show More'}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 order-xl-1">
                            <div className="card bg-secondary shadow">
                                <div className="card-header bg-white border-0">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">My account</h3>
                                        </div>





                                        <div className="col-4 text-right d-flex justify-content-end align-items-center button-container">
                                            <GradientButton
                                                onClick={handlePdfClick}
                                                text="AI Generate"
                                                className="ai-generate-btn"
                                            />
                                            {isEditing ? (
                                                <button onClick={handleSave} className="btn btn-sm btn-primary">SAVE</button>
                                            ) : (
                                                <button onClick={handleEdit} className="btn btn-sm btn-primary">EDIT</button>
                                            )}
                                        </div>

                                        {showFileUpload && (
                                            <div className="file-upload-overlay">
                                                <div className="file-upload-modal">
                                                    {isProcessing ? (
                                                        <div className="processing-overlay">
                                                            <Loader />
                                                            <p>Processing your resume...</p>
                                                        </div>
                                                    ) : (
                                                        <FileUploadBox
                                                            onClose={() => setShowFileUpload(false)}
                                                            onFileSelect={handlePdfFile}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <h6 className="heading-small text-muted mb-4">Basic information</h6>
                                        <div className="pl-lg-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label  required-field" htmlFor="input-firstname">First Name</label>
                                                        <input type="text" id="input-firstname" name="FirstName" className="form-control form-control-alternative" value={userData.FirstName} onChange={handleInputChange} readOnly={!isEditing} required />
                                                        {renderErrorMessage('FirstName')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label required-field" htmlFor="input-lastname">Last Name</label>
                                                        <input type="text" id="input-lastname" name="LastName" className="form-control form-control-alternative" value={userData.LastName} onChange={handleInputChange} readOnly={!isEditing} required />
                                                        {renderErrorMessage('LastName')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-email">Email</label>
                                                        <input type="email" id="input-email" className="form-control form-control-alternative" value={userData.Email} readOnly required />
                                                        {renderErrorMessage('Email')}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-mobile">Mobile</label>
                                                        <PhoneInput
                                                            country='in'
                                                            countryCodeEditable={false}
                                                            placeholder='Enter your mobile number'
                                                            disabled={!isEditing}
                                                            value={userData.Mobile ? userData.Mobile.replace('-', '') : ''}
                                                            onChange={handlePhoneChange}
                                                            autoFormat={false}
                                                            searchClass='search-class'
                                                            inputStyle={{ width: '100%', maxWidth: '400px' }}
                                                            searchStyle={{ margin: '0', width: '97%', height: '30px' }}
                                                            enableSearch
                                                            disableSearchIcon
                                                            searchNotFound='Not found'
                                                            inputExtraProps={{
                                                                name: 'mobile',
                                                                required: true,
                                                                autoFocus: false,
                                                            }}

                                                        />

                                                        {phoneError !== '' && <p style={{ color: 'red' }}>{phoneError}</p>}




                                                    </div>

                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-dob">Date of Birth</label>
                                                        <input type="date" id="input-dob" name="DateOfBirth" className="form-control form-control-alternative" value={userData.DateOfBirth} onChange={handleInputChange} readOnly={!isEditing} required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-gender">Gender</label>
                                                        <select id="input-gender" name="Gender" className="form-control form-control-alternative" value={userData.Gender} onChange={(e) => {
                                                            handleInputChange(e);
                                                            const gender = e.target.value;
                                                            const avatars = gender === 'Male' ? maleAvatars : gender === 'Female' ? femaleAvatars : [...maleAvatars, ...femaleAvatars];
                                                            setAvatarIndex(0); // Show the first avatar of the selected gender
                                                            setUserData(prevState => ({
                                                                ...prevState,
                                                                profilePic: avatars[0]
                                                            }));
                                                        }} disabled={!isEditing} required>
                                                            <option value="">Select Gender</option>
                                                            {genderOptions.map((gender, index) => (
                                                                <option key={index} value={gender}>{gender}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-city">City</label>
                                                        <input type="text" id="input-city" name="city" className="form-control form-control-alternative" value={userData.city} onChange={handleInputChange} readOnly={!isEditing} required />

                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-state">State</label>
                                                        <input type="text" id="input-state" name="state" className="form-control form-control-alternative" value={userData.state} onChange={handleInputChange} readOnly={!isEditing} required />

                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group">
                                                        <label className="form-control-label required-field" htmlFor="input-country">Country</label>
                                                        <input type="text" id="input-country" name="country" className="form-control form-control-alternative" value={userData.country} onChange={handleInputChange} readOnly={!isEditing} required />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Address */}
                                        <h6 className="heading-small text-muted mb-4">Experience and Skills</h6>
                                        <div className="pl-lg-4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-resume">Link to your Resume / CV</label>
                                                        <input id="input-resume" name="ResumeLink" className="form-control form-control-alternative" value={userData.ResumeLink} onChange={handleInputChange} readOnly={!isEditing} type="text" required />
                                                        {renderErrorMessage('ResumeLink')}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-designation">Designation</label>
                                                        <input id="input-designation" name="Designation" className="form-control form-control-alternative" value={userData.Designation} onChange={handleInputChange} readOnly={!isEditing} type="text" required />
                                                        {renderErrorMessage('Designation')}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-educational-qualification">Educational Qualification</label>
                                                        <input id="input-educational-qualification" name="EducationalQualification" className="form-control form-control-alternative" value={userData.EducationalQualification} onChange={handleInputChange} readOnly={!isEditing} type="text" required />
                                                        {renderErrorMessage('EducationalQualification')}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-educational-institute">Educational Institute</label>
                                                        <input id="input-educational-institute" name="EducationalInstitute" className="form-control form-control-alternative" value={userData.EducationalInstitute} onChange={handleInputChange} readOnly={!isEditing} type="text" required />
                                                        {renderErrorMessage('EducationalInstitute')}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label required-field" htmlFor="input-github">Github</label>
                                                        <input type="text" id="input-github" name="GithubLink" className="form-control form-control-alternative" value={userData.GithubLink} onChange={handleInputChange} readOnly={!isEditing} required />
                                                        {renderErrorMessage('GithubLink')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group focused">
                                                        <label className="form-control-label" htmlFor="input-linkedin">LinkedIn</label>
                                                        <input type="text" id="input-linkedin" name="LinkedinLink" className="form-control form-control-alternative" value={userData.LinkedinLink} onChange={handleInputChange} readOnly={!isEditing} required />
                                                        {renderErrorMessage('LinkedinLink')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group">
                                                        <label className="form-control-label" htmlFor="input-instagram">Instagram</label>
                                                        <input type="text" id="input-instagram" name="InstagramLink" className="form-control form-control-alternative" value={userData.InstagramLink} onChange={handleInputChange} readOnly={!isEditing} required />
                                                        {renderErrorMessage('InstagramLink')}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group focused">
                                                <label className="form-control-label required-field" htmlFor="input-skills">Skills</label>
                                                {isEditing ? (
                                                    <SkillsDropdown selectedSkills={selectedSkills} setSelectedSkills={setSelectedSkills} options={skillOptions} />
                                                ) : (
                                                    <div className="selected-tech-stack">
                                                        {selectedSkills.map((skill, index) => (
                                                            <span key={index} className="tech-stack-tag">
                                                                {skill}
                                                            </span>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>




                                        {/* More About Me */}
                                        <h6 className="heading-small text-muted mb-4">More About Me</h6>
                                        <div className="pl-lg-4">
                                            <div className="form-group focused">
                                                <label><b>About Me</b> <span className="required-field"></span></label>
                                                <textarea rows="4" name="AboutMe" className="form-control form-control-alternative" readOnly={!isEditing} value={userData.AboutMe} onChange={handleInputChange} required></textarea>
                                                {renderErrorMessage('AboutMe')}
                                            </div>
                                            <div className="form-group focused">
                                                <label><b>My Experience</b> <span className="required-field"></span></label>
                                                <textarea rows="4" name="MyExperience" className="form-control form-control-alternative required-field" readOnly={!isEditing} value={userData.MyExperience} onChange={handleInputChange} required></textarea>
                                                {renderErrorMessage('MyExperience')}
                                            </div>
                                            <div className="form-group focused">
                                                <label><b>Past Projects</b> <span className="required-field"></span></label>
                                                <textarea rows="4" name="PastProjects" className="form-control form-control-alternative required-field" readOnly={!isEditing} value={userData.PastProjects} onChange={handleInputChange} required></textarea>
                                                {renderErrorMessage('PastProjects')}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>

                {`

                :root {
    --blue: #5e72e4;
    --indigo: #5603ad;
    --purple: #8965e0;
    --pink: #f3a4b5;
    --red: #f5365c;
    --orange: #fb6340;
    --yellow: #ffd600;
    --green: #2dce89;
    --teal: #11cdef;
    --cyan: #2bffc6;
    --white: #fff;
    --gray: #8898aa;
    --gray-dark: #32325d;
    --light: #ced4da;
    --lighter: #e9ecef;
    --primary: #5e72e4;
    --secondary: #f7fafc;
    --success: #2dce89;
    --info: #11cdef;
    --warning: #fb6340;
    --danger: #f5365c;
    --light: #adb5bd;
    --dark: #212529;
    --default: #172b4d;
    --white: #fff;
    --neutral: #fff;
    --darker: black;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: Open Sans, sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
    width: device-width;
}

figcaption,
footer,
header,
main,
nav,
section {
    display: block;
}

body {
    font-family: Open Sans, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    color: #525f7f;
    background-color: #f8f9fe;
}

[tabindex='-1']:focus {
    outline: 0 !important;
}

hr {
    overflow: visible;
    box-sizing: content-box;
    height: 0;
}

h1,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul ul {
    margin-bottom: 0;
}

dfn {
    font-style: italic;
}

strong {
    font-weight: bolder;
}

a {
    text-decoration: none;
    color: #5e72e4;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    text-decoration: none;
    color: #233dd2;
}

a:not([href]):not([tabindex]) {
    text-decoration: none;
    color: inherit;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    text-decoration: none;
    color: inherit;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

img {
    vertical-align: middle;
    border-style: none;
}

caption {
    padding-top: 1rem;
    padding-bottom: 1rem;
    caption-side: bottom;
    text-align: left;
    color: #8898aa;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

legend {
    font-size: 1.5rem;
    line-height: inherit;
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0;
    white-space: normal;
    color: inherit;
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

[hidden] {
    display: none !important;
}

h1,
h3,
h4,
h5,
h6,
.h1,
.h3,
.h4,
.h5,
.h6 {
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: .5rem;
    color: #32325d;
}

h1,
.h1 {
    font-size: 1.625rem;
}

h3,
.h3 {
    font-size: 1.0625rem;
}

h4,
.h4 {
    font-size: .9375rem;
}

h5,
.h5 {
    font-size: .8125rem;
}

h6,
.h6 {
    font-size: .625rem;
}

.display-2 {
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.5;
}

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

code {
    font-size: 87.5%;
    word-break: break-word;
    color: #f3a4b5;
}

a>code {
    color: inherit;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.nowrap{
    white-space: nowrap;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
}

.col-4,
.col-8,
.col,
.col-md-10,
.col-md-12,
.col-lg-3,
.col-lg-4,
.col-lg-6,
.col-lg-7,
.col-xl-4,
.col-xl-6,
.col-xl-8 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
}

.col-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
}

.col-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
}

@media (min-width: 768px) {

    .col-md-10 {
        max-width: 83.33333%;
        flex: 0 0 83.33333%;
    }

    .col-md-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 992px) {

    .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-lg-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
    }

    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-lg-7 {
        max-width: 58.33333%;
        flex: 0 0 58.33333%;
    }

    .order-lg-2 {
        order: 2;
    }
}

@media (min-width: 1200px) {

    .col-xl-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
    }

    .col-xl-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-xl-8 {
        max-width: 66.66667%;
        flex: 0 0 66.66667%;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }
}

.file-upload-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.file-upload-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-control {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: .625rem .75rem;
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
    color: #8898aa;
    border: 1px solid #cad1d7;
    border-radius: .375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    border: 0;
    background-color: transparent;
}

.form-control:focus {
    color: #8898aa;
    border-color: rgba(50, 151, 211, .25);
    outline: 0;
    background-color: #fff;
    box-shadow: none, none;
}

.form-control:-ms-input-placeholder {
    opacity: 1;
    color: #adb5bd;
}

.form-control::-ms-input-placeholder {
    opacity: 1;
    color: #adb5bd;
}

.form-control::placeholder {
    opacity: 1;
    color: #adb5bd;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 1;
    background-color: #e9ecef;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.processing-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
}

.processing-overlay p {
    margin-top: 4rem;
    color: #525f7f;
    font-weight: 500;
}

.required-field::after {
    content: ' *';
    color: #f5365c;
    font-weight: bold;
}

.button-container {
    gap: 10px;
}

.ai-generate-btn {
    min-width: fit-content;
}

.home-back-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 15px;
    overflow: hidden;
    transition-duration: 0.3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: rgba(93,89,235,255);
}

.home-back-button-sign {
    width: 100%;
    transition-duration: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-back-button-sign svg {
    width: 17px;
}

.home-back-button-sign svg path {
    fill: white;
}

.home-back-button-text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    transition-duration: 0.3s;
}

.home-back-button:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: 0.3s;
}

.home-back-button:hover .home-back-button-sign {
    width: 30%;
    transition-duration: 0.3s;
    padding-left: 20px;
}

.home-back-button:hover .home-back-button-text {
    opacity: 1;
    width: 70%;
    transition-duration: 0.3s;
    padding-right: 10px;
}

.home-back-button:active {
    transform: translate(2px, 2px);
}


    

@media (max-width: 576px) {
    .button-container {
        justify-content: center !important;
        width: 100%;
        margin-top: 10px;
    }

    .col-8,
    .col-4 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
}


@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        margin-bottom: 0;
        align-items: center;
        justify-content: center;
    }

    .form-inline .form-group {
        display: flex;
        margin-bottom: 0;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .input-group {
        width: auto;
    }
}

.btn {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    display: inline-block;
    padding: .625rem 1.25rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: .375rem;
}

@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover,
.btn:focus {
    text-decoration: none;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.btn:disabled {
    opacity: .65;
    box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08), none;
}

.btn-primary {
    color: #fff;
    border-color: ##524be6;
    background-color: ##524be6;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-primary:hover {
    color: #fff;
    border-color: #4940e2;
    background-color: #4940e2;
}

.btn-primary:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-primary:disabled {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
}

.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #5e72e4;
    background-color: #324cdd;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, .5);
}

.btn-info {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-info:hover {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
}

.btn-info:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(17, 205, 239, .5);
}

.btn-info:disabled {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
}

.btn-info:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #11cdef;
    background-color: #0da5c0;
}

.btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(17, 205, 239, .5);
}

.btn-default {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-default:hover {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
}

.btn-default:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(23, 43, 77, .5);
}

.btn-default:disabled {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
}

.btn-default:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #172b4d;
    background-color: #0b1526;
}

.btn-default:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(23, 43, 77, .5);
}

.btn-sm {
    font-size: .875rem;
    line-height: 1.5;
    padding: .25rem .5rem;
    border-radius: .375rem;
}
    

.dropdown {
    position: relative;
}

.dropdown-menu {
    font-size: 1rem;
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 10rem;
    margin: .125rem 0 0;
    padding: .5rem 0;
    list-style: none;
    text-align: left;
    color: #525f7f;
    border: 0 solid rgba(0, 0, 0, .15);
    border-radius: .4375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1);
}

.dropdown-menu.show {
    display: block;
    opacity: 1;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    overflow: hidden;
    height: 0;
    margin: .5rem 0;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    font-weight: 400;
    display: block;
    clear: both;
    width: 100%;
    padding: .25rem 1.5rem;
    text-align: inherit;
    white-space: nowrap;
    color: #212529;
    border: 0;
    background-color: transparent;
}

.dropdown-item:hover,
.dropdown-item:focus {
    text-decoration: none;
    color: #16181b;
    background-color: #f6f9fc;
}

.dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #5e72e4;
}

.dropdown-item:disabled {
    color: #8898aa;
    background-color: transparent;
}

.dropdown-header {
    font-size: .875rem;
    display: block;
    margin-bottom: 0;
    padding: .5rem 1.5rem;
    white-space: nowrap;
    color: #8898aa;
}

.input-group {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: stretch;
}

.input-group>.form-control {
    position: relative;
    width: 1%;
    margin-bottom: 0;
    flex: 1 1 auto;
}

.input-group>.form-control+.form-control {
    margin-left: -1px;
}

.input-group>.form-control:focus {
    z-index: 3;
}

.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend {
    display: flex;
}

.input-group-prepend .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    margin-bottom: 0;
    padding: .625rem .75rem;
    text-align: center;
    white-space: nowrap;
    color: #adb5bd;
    border: 1px solid #cad1d7;
    border-radius: .375rem;
    background-color: #fff;
    align-items: center;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
    margin-top: 0;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.nav {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    flex-wrap: wrap;
}

.nav-link {
    display: block;
    padding: .25rem .75rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.navbar {
    position: relative;
    display: flex;
    padding: 1rem 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap;
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .95);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, .65);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: .375rem;
    background-color: #fff;
    background-clip: border-box;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card-body {
    padding: 1.5rem;
    flex: 1 1 auto;
}

.card-header {
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: #fff;
}

.card-header:first-child {
    border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    font-size: .75rem;
    display: flex;
    overflow: hidden;
    height: 1rem;
    border-radius: .375rem;
    background-color: #e9ecef;
    box-shadow: inset 0 .1rem .1rem rgba(0, 0, 0, .1);
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1 1;
}

.bg-secondary {
    background-color: #f7fafc !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #d2e3ee !important;
}

.bg-default {
    background-color: #172b4d !important;
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
    background-color: #0b1526 !important;
}

.bg-white {
    background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.bg-white {
    background-color: #fff !important;
}

.border-0 {
    border: 0 !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

@media (min-width: 768px) {

    .d-md-flex {
        display: flex !important;
    }
}

@media (min-width: 992px) {

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.align-items-center {
    align-items: center !important;
}

@media (min-width: 1200px) {

    .justify-content-xl-between {
        justify-content: space-between !important;
    }
}

.float-right {
    float: right !important;
}

.shadow,
.card-profile-image img {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mr-2 {
    margin-right: .5rem !important;
}

.ml-2 {
    margin-left: .5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mt--7 {
    margin-top: -6rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pt-8 {
    padding-top: 8rem !important;
}

.pb-8 {
    padding-bottom: 8rem !important;
}

.m-auto {
    margin: auto !important;
}

@media (min-width: 768px) {

    .mt-md-5 {
        margin-top: 3rem !important;
    }

    .pt-md-4 {
        padding-top: 1.5rem !important;
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
}

@media (min-width: 992px) {

    .pl-lg-4 {
        padding-left: 1.5rem !important;
    }

    .pt-lg-8 {
        padding-top: 8rem !important;
    }

    .ml-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {

    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.text-white {
    color: #fff !important;
}

.text-white {
    color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
    color: #e6e6e6 !important;
}

.text-muted {
    color: #8898aa !important;
}

@media print {

    *,
    *::before,
    *::after {
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img {
        page-break-inside: avoid;
    }

    p,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }
}

figcaption,
main {
    display: block;
}

main {
    overflow: hidden;
}

.bg-white {
    background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.bg-gradient-default {
    background: linear-gradient(87deg, #6366f1 0, #6366f1 50%, #6366f1 50%, #6a11ef 100%) !important;
}

.bg-gradient-default {
    background: linear-gradient(87deg, #6366f1 0, #6366f1 50%, #6366f1 50%, #6a11ef 100%) !important;
}

@keyframes floating-lg {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes floating-sm {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0px);
    }
}

.opacity-8 {
    opacity: .8 !important;
}

.opacity-8 {
    opacity: .9 !important;
}

.center {
    left: 50%;
    transform: translateX(-50%);
}

[class*='shadow'] {
    transition: all .15s ease;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.text-sm {
    font-size: .875rem !important;
}

.text-white {
    color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
    color: #e6e6e6 !important;
}

.avatar-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.avatar-control-btn {
  background-color:  #5e72e4;
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.avatar-control-btn:hover {
  background-color: #4e52d0;
}

.avatar-selection-text {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.avatar-control-btn i {
  font-size: 1.5rem;
}


.btn {
    font-size: .875rem;
    position: relative;
    transition: all .15s ease;
    letter-spacing: .025em;
    text-transform: none;
    will-change: transform;
}

.btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.btn:not(:last-child) {
    margin-right: .5rem;
}

.btn i:not(:first-child) {
    margin-left: .5rem;
}

.btn i:not(:last-child) {
    margin-right: .5rem;
}

.input-group .btn {
    margin-right: 0;
    transform: translateY(0);
}

.btn-sm {
    font-size: .75rem;
}

[class*='btn-outline-'] {
    border-width: 1px;
}

.card-profile-image {
    position: relative;
    
}

.card-profile-image img {
    position: absolute;
    left: 50%;
    max-width: 180px;
    transition: all .15s ease;
    transform: translate(-50%, -30%);
    border-radius: .375rem;
}

.card-profile-image img:hover {
    transform: translate(-50%, -33%);
}

.card-profile-image:hover .avatar-overlay {
    opacity: 1;
}


.card-profile-stats {
    padding: 1rem 0;
}

.card-profile-stats>div {
    margin-right: 1rem;
    padding: .875rem;
    text-align: center;
}

.card-profile-stats>div:last-child {
    margin-right: 0;
}

.card-profile-stats>div .heading {
    font-size: 1.1rem;
    font-weight: bold;
    display: block;
}

.card-profile-stats>div .description {
    font-size: .875rem;
    color: #adb5bd;
}

.main-content {
    position: relative;
}

.main-content .navbar-top {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
    background-color: transparent;
}

@media (min-width: 768px) {
    .main-content .container-fluid {
        padding-right: 39px !important;
        padding-left: 39px !important;
    }
}

.dropdown {
    display: inline-block;
}

.dropdown-menu {
    min-width: 12rem;
}

.dropdown-menu .dropdown-item {
    font-size: .875rem;
    padding: .5rem 1rem;
}

.dropdown-menu .dropdown-item>i {
    font-size: 1rem;
    margin-right: 1rem;
    vertical-align: -17%;
}

.dropdown-header {
    font-size: .625rem;
    font-weight: 700;
    padding-right: 1rem;
    padding-left: 1rem;
    text-transform: uppercase;
    color: #f6f9fc;
}

.dropdown-menu a.media>div:first-child {
    line-height: 1;
}

.dropdown-menu a.media p {
    color: #8898aa;
}

.dropdown-menu a.media:hover .heading,
.dropdown-menu a.media:hover p {
    color: #172b4d !important;
}

.footer {
    padding: 2.5rem 0;
    background: #f7fafc;
}

.footer .nav .nav-item .nav-link {
    color: #8898aa !important;
}

.footer .nav .nav-item .nav-link:hover {
    color: #525f7f !important;
}

.footer .copyright {
    font-size: .875rem;
}

.form-control-label {
    font-size: .875rem;
    font-weight: 600;
    color: #525f7f;
}

.form-control {
    font-size: .875rem;
}

.form-control:focus:-ms-input-placeholder {
    color: #adb5bd;
}

.form-control:focus::-ms-input-placeholder {
    color: #adb5bd;
}

.form-control:focus::placeholder {
    color: #adb5bd;
}

textarea[resize='none'] {
    resize: none !important;
}

textarea[resize='both'] {
    resize: both !important;
}

textarea[resize='vertical'] {
    resize: vertical !important;
}

textarea[resize='horizontal'] {
    resize: horizontal !important;
}

.form-control-alternative {
    transition: box-shadow .15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
}

.form-control-alternative:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.input-group {
    transition: all .15s ease;
    border-radius: .375rem;
    box-shadow: none;
}

.input-group .form-control {
    box-shadow: none;
}

.input-group .form-control:not(:first-child) {
    padding-left: 0;
    border-left: 0;
}

.input-group .form-control:not(:last-child) {
    padding-right: 0;
    border-right: 0;
}

.input-group .form-control:focus {
    box-shadow: none;
}

.input-group-text {
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
}

.input-group-alternative {
    transition: box-shadow .15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
}

.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
    border: 0;
    box-shadow: none;
}

.focused .input-group-alternative {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
}

.focused .input-group {
    box-shadow: none;
}

.focused .input-group-text {
    color: #8898aa;
    border-color: rgba(50, 151, 211, .25);
    background-color: #fff;
}

.focused .form-control {
    border-color: rgba(50, 151, 211, .25);
}

.header {
    position: relative;
}

.input-group {
    transition: all .15s ease;
    border-radius: .375rem;
    box-shadow: none;
}

.input-group .form-control {
    box-shadow: none;
}

.input-group .form-control:not(:first-child) {
    padding-left: 0;
    border-left: 0;
}

.input-group .form-control:not(:last-child) {
    padding-right: 0;
    border-right: 0;
}

.input-group .form-control:focus {
    box-shadow: none;
}

.input-group-text {
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
}

.input-group-alternative {
    transition: box-shadow .15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
}

.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
    border: 0;
    box-shadow: none;
}

.focused .input-group-alternative {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
}

.focused .input-group {
    box-shadow: none;
}

.focused .input-group-text {
    color: #8898aa;
    border-color: rgba(50, 151, 211, .25);
    background-color: #fff;
}

.focused .form-control {
    border-color: rgba(50, 151, 211, .25);
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .15s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .mask {
        transition: none;
    }
}

.nav-link {
    color: #525f7f;
}

.nav-link:hover {
    color: #5e72e4;
}

.nav-link i.ni {
    position: relative;
    top: 2px;
}

.navbar-search .input-group {
    border: 2px solid;
    border-radius: 2rem;
    background-color: transparent;
}

.navbar-search .input-group .input-group-text {
    padding-left: 1rem;
    background-color: transparent;
}

.navbar-search .form-control {
    width: 270px;
    background-color: transparent;
}

.navbar-search-dark .input-group {
    border-color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .input-group-text {
    color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .form-control {
    color: rgba(255, 255, 255, .9);
}

.navbar-search-dark .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .form-control::placeholder {
    color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .focused .input-group {
    border-color: rgba(255, 255, 255, .9);
}

@media (min-width: 768px) {
    .navbar .dropdown-menu {
        margin: 0;
        pointer-events: none;
        opacity: 0;
    }

    .navbar .dropdown-menu-arrow:before {
        position: absolute;
        z-index: -5;
        bottom: 100%;
        left: 20px;
        display: block;
        width: 12px;
        height: 12px;
        content: '';
        transform: rotate(-45deg) translateY(12px);
        border-radius: 2px;
        background: #fff;
        box-shadow: none;
    }

    .navbar .dropdown-menu-right:before {
        right: 20px;
        left: auto;
    }

    @keyframes show-navbar-dropdown {
        0% {
            transition: visibility .25s, opacity .25s, transform .25s;
            transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
            opacity: 0;
        }

        100% {
            transform: translate(0, 0);
            opacity: 1;
        }
    }

    @keyframes hide-navbar-dropdown {
        from {
            opacity: 1;
        }

        to {
            transform: translate(0, 10px);
            opacity: 0;
        }
    }
}

@media (max-width: 767.98px) {
    .navbar-nav .nav-link {
        padding: .625rem 0;
        color: #172b4d !important;
    }

    .navbar-nav .dropdown-menu {
        min-width: auto;
        box-shadow: none;
    }
}

@keyframes show-navbar-collapse {
    0% {
        transform: scale(.95);
        transform-origin: 100% 0;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes hide-navbar-collapse {
    from {
        transform: scale(1);
        transform-origin: 100% 0;
        opacity: 1;
    }

    to {
        transform: scale(.95);
        opacity: 0;
    }
}

.progress {
    overflow: hidden;
    height: 8px;
    margin-bottom: 1rem;
    border-radius: .25rem;
    background-color: #e9ecef;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
}

.description {
    font-size: .875rem;
}

.heading {
    font-size: .95rem;
    font-weight: 600;
    letter-spacing: .025em;
    text-transform: uppercase;
}

.heading-small {
    font-size: .75rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    letter-spacing: .04em;
    text-transform: uppercase;
}

.display-2 span {
    font-weight: 300;
    display: block;
}


#navbar .navbar {
    margin-bottom: 20px;
}

                `}

            </style>
        </div >

    );
};

export default Profile;